

























































import { Component, Vue } from "vue-property-decorator";
import {
    apiServiceGetConfig,
    apiServiceSetConfig,
} from "@/api/marketing/service";
import MaterialSelect from "@/components/material-select/index.vue";

interface Form {
    duration: number;
    status: number;
}

@Component({
    components: {
        MaterialSelect,
    },
})
export default class Footprint extends Vue {
    form: any = {
        service_type: '1',
        phone: 123456,
        qr_code: "http://www.likeshopb2cv3.localhost/qr.png",
        wechat: "lsv3",
        online_type: 1,
        h5_url: "h5.jpg",
    };

    /** S Methods **/

    getServicegetConfig() {
        apiServiceGetConfig({}).then((res) => {
            this.form = res;
        });
    }

    onSubmit() {
        apiServiceSetConfig({...this.form}).then(res => {
            this.getServicegetConfig();
        })
    }

    /** E Methods **/

    /** Life Cycle **/
    created() {
        // 初始化数据
        this.getServicegetConfig();
    }
}
